import * as PIXI from 'pixi.js';
import { _pixi_math_extras } from '@pixi/math-extras';
import axios from "axios";
import { gsap, Power4, Expo, Linear, Circ } from "gsap";
import { sound } from '@pixi/sound';
import snowflake1 from "../img/snowflakes/snowflake01.png";
import snowflake2 from "../img/snowflakes/snowflake02.png";
import soundIconOn from "../img/sound_icon_on.png";
import soundIconOff from "../img/sound_icon_off.png";
import line from "../img/line.png";
import music from "../sound/music.mp3";
import dieSound from "../sound/die.mp3";
import collectSound1 from "../sound/collect_sound1.mp3";
import collectSound2 from "../sound/collect_sound2.mp3";
import collectSound3 from "../sound/collect_sound3.mp3";
import collectSound4 from "../sound/collect_sound4.mp3";
import clockSound from "../sound/clock_sound.mp3";
import starSound from "../sound/star_sound.mp3";

// 2022 imports
import backgroundDesktop from "../img/background960x600.png";
import backgroundMobile from "../img/background390x844.png";
import border from "../img/border.png";
import foregroundDesktop from "../img/foreground960x600.png";
import foregroundMobile from "../img/foreground390x844.png";
import corner1 from "../img/corner1.png";
import corner2 from "../img/corner2.png";
import corner3 from "../img/corner3.png";
import corner4 from "../img/corner4.png";
import bag from "../img/bag.png";
import ball1 from "../img/ball1.png";
import ball2 from "../img/ball2.png";
import ball3 from "../img/ball3.png";
import ball4 from "../img/ball4.png";
import ball5 from "../img/ball5.png";
import ball6 from "../img/ball6.png";
import ball7 from "../img/ball7.png";
import ball8 from "../img/ball8.png";
import ball9 from "../img/ball9.png";
import ball10 from "../img/ball10.png";
import ball11 from "../img/ball11.png";
import ball12 from "../img/ball12.png";
import ball13 from "../img/ball13.png";
import ball14 from "../img/ball14.png";
import bomb1 from "../img/bomb1.png";
import bomb2 from "../img/bomb2.png";
import bomb3 from "../img/bomb3.png";
import bomb4 from "../img/bomb4.png";
import bomb5 from "../img/bomb5.png";
import bomb6 from "../img/bomb6.png";
import bomb7 from "../img/bomb7.png";
import bomb8 from "../img/bomb8.png";
import bomb9 from "../img/bomb9.png";
import bomb10 from "../img/bomb10.png";
import bomb11 from "../img/bomb11.png";
import bomb12 from "../img/bomb12.png";
import bomb13 from "../img/bomb13.png";
import bomb14 from "../img/bomb14.png";
import bomb15 from "../img/bomb15.png";
import bomb16 from "../img/bomb16.png";
import clock1 from "../img/clock1.png";
import clock2 from "../img/clock2.png";
import clock3 from "../img/clock3.png";
import clock4 from "../img/clock4.png";
import star1 from "../img/star1.png";
import star2 from "../img/star2.png";
import star3 from "../img/star3.png";
import star4 from "../img/star4.png";
import star5 from "../img/star5.png";
import goodFrame1 from "../img/goodFrame1.png";
import goodFrame2 from "../img/goodFrame2.png";
import goodFrame3 from "../img/goodFrame3.png";
import goodFrame4 from "../img/goodFrame4.png";
import badFrame1 from "../img/badFrame1.png";
import badFrame2 from "../img/badFrame2.png";
import badFrame3 from "../img/badFrame3.png";
import evilDelete1 from "../img/evil_delete1.png";
import evilDelete2 from "../img/evil_delete2.png";
import evilDelete3 from "../img/evil_delete3.png";
import evilDelete4 from "../img/evil_delete4.png";
import evilDelete5 from "../img/evil_delete5.png";
import evilDelete6 from "../img/evil_delete6.png";
import left from "../img/left.png";
import right from "../img/right.png";
import again from "../img/again.png";
import sign_star from "../img/sign_star.png";
import sign_clock from "../img/sign_clock.png";
import score_bg from "../img/score_bg.png";
import point1 from "../img/1.png";
import point2 from "../img/2.png";
import point3 from "../img/3.png";
import point4 from "../img/4.png";
import point5 from "../img/5.png";
import point6 from "../img/6.png";
import point7 from "../img/7.png";
import point8 from "../img/8.png";
import point9 from "../img/9.png";
import point10 from "../img/10.png";
import point11 from "../img/11.png";
import point12 from "../img/12.png";
import point13 from "../img/13.png";
import point14 from "../img/14.png";
import point15 from "../img/15.png";
import point16 from "../img/16.png";
import point17 from "../img/17.png";
import point18 from "../img/18.png";
import point19 from "../img/19.png";
import point20 from "../img/20.png";
import timeOver from "../img/time_over.png";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function randomRange(minNum, maxNum) {
  return (Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum);
}

function getRandomFloat(min, max, decimals) {
  const str = (Math.random() * (max - min) + min).toFixed(decimals);

  return parseFloat(str);
}

const scale = (number, inMin, inMax, outMin, outMax) => {
  return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

const isNumberInRange = (num, range) => {
  return num >= range[0] && num <= range[1];
}

const DEFAULT_LOGICAL_WIDTH = 960; // DESKTOP
const DEFAULT_LOGICAL_HEIGHT = 600; // DESKTOP
const DEFAULT_AREA = DEFAULT_LOGICAL_WIDTH * DEFAULT_LOGICAL_HEIGHT;
let logicalWidth = DEFAULT_LOGICAL_WIDTH;
let logicalHeight = DEFAULT_LOGICAL_HEIGHT;
let ratio = logicalWidth / logicalHeight;
let speedCorrection = 1;
let scaleCorrection = 1;
let bagAccelerationCorrection = 1;

if (window.innerHeight > window.innerWidth) {
  logicalWidth = window.innerWidth;
  logicalHeight = window.innerHeight;
  ratio = logicalWidth / logicalHeight;

  scaleCorrection = scale(logicalWidth, 320, 960, .8, 1);
  speedCorrection = scale(logicalWidth, 320, 960, .65, 1);
  bagAccelerationCorrection = scale(logicalWidth, 320, 960, .9, 1);
}

let type = "WebGL";
if (!PIXI.utils.isWebGLSupported()) {
  type = "canvas";
}

PIXI.utils.sayHello(type);
PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;
PIXI.settings.TARGET_FPMS = 0.06;



const app = new PIXI.Application({
    width: logicalWidth,
    height: logicalHeight,
    antialias: false,
    roundPixels: true,
    resolution: window.devicePixelRatio || 1
  }
);

app.renderer.backgroundColor = 0xEBF2F5;
const mainContainer = document.getElementsByClassName('main-container').item(0);
mainContainer.appendChild(app.view);

let playerName = '';
const startScreen = document.getElementsByClassName('start-screen-section').item(0);
const playerNameInput = document.getElementById('player_name');
const playButtonInitial = document.getElementById('playInitial');
const startForm = document.getElementById('start_form');
const warningText = document.getElementsByClassName('start-screen-section__warning').item(0);
const tutorial1 = document.getElementById('tutorial1');
const tutorial2 = document.getElementById('tutorial2');
const next1Button = document.getElementById('next1');
const playButtonFinal = document.getElementById('playFinal');


let preloaderContainer, circle1, circle2, circle3, currentCircle;

const animatePreloader = delta => {
  currentCircle.alpha -= .03;
  if (currentCircle.alpha <= 0.2) {
    switch (currentCircle) {
      case circle1:
        currentCircle = circle2;
        break;

      case circle2:
        currentCircle = circle3;
        break;

      default:
        currentCircle = circle1;
        break;
    }
    currentCircle.alpha = 1;
  }
};

const showPreloader = () => {
  preloaderContainer = new PIXI.Container();

  const radius = 10;

  circle1 = new PIXI.Graphics();
  circle1.beginFill(0x527ca7);
  circle1.drawCircle(0, 0, radius, radius);
  circle1.endFill();
  preloaderContainer.addChild(circle1);

  circle2 = new PIXI.Graphics();
  circle2.beginFill(0x527ca7);
  circle2.drawCircle(0, 0, radius, radius);
  circle2.endFill();
  circle2.x = 30;
  circle2.alpha = .2;
  preloaderContainer.addChild(circle2);

  circle3 = new PIXI.Graphics();
  circle3.beginFill(0x527ca7);
  circle3.drawCircle(0, 0, radius, radius);
  circle3.endFill();
  circle3.x = circle2.x + 30;
  circle3.alpha = .2;
  preloaderContainer.addChild(circle3);

  preloaderContainer.x = (app.screen.width - preloaderContainer.width) * .5 + 10;
  preloaderContainer.y = (app.screen.height - preloaderContainer.height) * .5 + 10;

  app.stage.addChild(preloaderContainer);

  currentCircle = circle1;
  app.ticker.add(animatePreloader);
};

const removePreloader = () => {
  app.ticker.remove(animatePreloader);
  preloaderContainer.destroy({ children: true });
};



playerNameInput.addEventListener('input', (event) => {
  warningText.style.display = 'none';
});

const goToTutorials = () => {
  if (playerNameInput.value.length === 0) {
    warningText.style.display = 'block';
    return;
  }

  playerName = String(playerNameInput.value).trim();

  startScreen.remove();

  tutorial1.style.display = "flex";
  resizeHandler();
}

playButtonInitial.addEventListener("click", () => {
  goToTutorials();
});

startForm.addEventListener("submit", (event) => {
  event.preventDefault();
  goToTutorials();
})

next1Button.addEventListener("click", () => {
  tutorial1.remove();
  tutorial2.style.display = "flex";
  resizeHandler();
});

playButtonFinal.addEventListener("click", () => {
  tutorial2.remove();

  mainContainer.style.display = "block";

  showPreloader();

  PIXI.Loader.shared
  .add(snowflake1)
  .add(snowflake2)
  .add([soundIconOn, soundIconOff])
  .add(line)
  .add(music)
  .add(dieSound)
  .add(collectSound1)
  .add(collectSound2)
  .add(collectSound3)
  .add(collectSound4)
  .add(starSound)
  .add(clockSound)

  .add(backgroundDesktop)
  .add(backgroundMobile)
  .add(foregroundDesktop)
  .add(foregroundMobile)
  .add(border)
  .add(corner1)
  .add(corner2)
  .add(corner3)
  .add(corner4)
  .add(bag)
  .add(clock1)
  .add(clock2)
  .add(clock3)
  .add(clock4)
  .add(bomb1)
  .add(bomb2)
  .add(bomb3)
  .add(bomb4)
  .add(bomb5)
  .add(bomb6)
  .add(bomb7)
  .add(bomb8)
  .add(bomb9)
  .add(bomb10)
  .add(bomb11)
  .add(bomb12)
  .add(bomb13)
  .add(bomb14)
  .add(bomb15)
  .add(bomb16)
  .add(ball1)
  .add(ball2)
  .add(ball3)
  .add(ball4)
  .add(ball5)
  .add(ball6)
  .add(ball7)
  .add(ball8)
  .add(ball9)
  .add(ball10)
  .add(ball11)
  .add(ball12)
  .add(ball13)
  .add(ball14)
  .add(star1)
  .add(star2)
  .add(star3)
  .add(star4)
  .add(star5)
  .add(goodFrame1)
  .add(goodFrame2)
  .add(goodFrame3)
  .add(goodFrame4)
  .add(badFrame1)
  .add(badFrame2)
  .add(badFrame3)
  .add(evilDelete1)
  .add(evilDelete2)
  .add(evilDelete3)
  .add(evilDelete4)
  .add(evilDelete5)
  .add(evilDelete6)
  .add(left)
  .add(right)
  .add(again)
  .add(sign_star)
  .add(sign_clock)
  .add(score_bg)
  .add(point1)
  .add(point2)
  .add(point3)
  .add(point4)
  .add(point5)
  .add(point6)
  .add(point7)
  .add(point8)
  .add(point9)
  .add(point10)
  .add(point11)
  .add(point12)
  .add(point13)
  .add(point14)
  .add(point15)
  .add(point16)
  .add(point17)
  .add(point18)
  .add(point19)
  .add(point20)
  .add(timeOver)
  .load(setup);
  resizeHandler();
});

const GAME_STATE_RUNNING = 'GAME_STATE_RUNNING';
const GAME_STATE_CRASHED = 'GAME_STATE_CRASHED';
const GAME_STATE_GAME_OVER = 'GAME_STATE_GAME_OVER';
const CRASH_DECELERATION_FACTOR = 1.04;
const ACCELERATION_CONSTANT = 0.000125; // 0.0002; // 0.000035;


let score = 0;
let gameState = GAME_STATE_RUNNING;
let acceleration = 1;
const backgroundContainer = new PIXI.Container();
let bagAnimatedSprite;
let bagGoodAnimationSprite;
let bagBadAnimationSprite;
const itemsContainer = new PIXI.Container();
const badDeleteContainer = new PIXI.Container();
const pointAnimationsContainer = new PIXI.Container();
const bagContainer = new PIXI.Container();
const bordersContainer = new PIXI.Container();
let gameOverContainer;
let standingData = [];
let playerData = {};
let signStarSprite;
let signClockSprite;
const collectSounds = [collectSound1, collectSound2, collectSound3, collectSound4];

//Score
const scoreTextContainer = new PIXI.Container();
const scoreText = new PIXI.Text('0',{fontFamily : 'Inter', fontSize: 12, fontWeight: 900, fill : 0xFFFFFF, align : 'left'});
const MOBILE_SCORE_TIME_PADDING = 12;
const DESKTOP_SCORE_TIME_PADDING = 70;
const initScoreTextField = () => {
  const bgTexture = PIXI.Texture.from(score_bg);
  const bgSprite = PIXI.Sprite.from(bgTexture);

  scoreTextContainer.addChild(bgSprite);

  let label = new PIXI.Text('0',{fontFamily : 'Inter', fontSize: 12, fontWeight: 900, fill : 0xFFFFFF, align : 'right'});
  label.text = 'SCORE';
  label.x = 10;
  label.y = (bgSprite.height - label.height) * .5;
  scoreTextContainer.addChild(label);

  scoreText.anchor.set(1, 0);
  scoreText.x = bgSprite.width - 10;
  scoreText.y = label.y;
  scoreTextContainer.addChild(scoreText);

  scoreTextContainer.x = ratio > 1 ? DESKTOP_SCORE_TIME_PADDING : MOBILE_SCORE_TIME_PADDING;
  scoreTextContainer.y = ratio > 1 ? DESKTOP_SCORE_TIME_PADDING : MOBILE_SCORE_TIME_PADDING;

  return scoreTextContainer;
};

const timeTextContainer = new PIXI.Container();
const timeText = new PIXI.Text('0',{fontFamily : 'Inter', fontSize: 12, fontWeight: 900, fill : 0xFFFFFF, align : 'left'});
const initTimeTextField = () => {
  const bgTexture = PIXI.Texture.from(score_bg);
  const bgSprite = PIXI.Sprite.from(bgTexture);

  timeTextContainer.addChild(bgSprite);

  let label = new PIXI.Text('0',{fontFamily : 'Inter', fontSize: 12, fontWeight: 900, fill : 0xFFFFFF, align : 'right'});
  label.text = 'TIME';
  label.x = 10;
  label.y = (bgSprite.height - label.height) * .5;
  timeTextContainer.addChild(label);

  timeText.anchor.set(1, 0);
  timeText.x = bgSprite.width - 10;
  timeText.y = label.y;
  timeTextContainer.addChild(timeText);

  timeTextContainer.x = app.screen.width - (ratio > 1 ? DESKTOP_SCORE_TIME_PADDING : MOBILE_SCORE_TIME_PADDING) - bgSprite.width;
  timeTextContainer.y = ratio > 1 ? DESKTOP_SCORE_TIME_PADDING : MOBILE_SCORE_TIME_PADDING;

  return timeTextContainer;
};

const soundIconContainer = new PIXI.Container();
let isSoundOn = true;
let soundInstance;
let collectableSoundInstance;
let crashSoundInstance;
let clockSoundInstance;
let starSoundInstance;
const SOUND_CONTAINER_MOBILE_Y = 7;
const SOUND_CONTAINER_DESKTOP_Y = 65;
const updateSoundIcon = () => {
  while(soundIconContainer.children.length > 0) {
    soundIconContainer.children[0].destroy({ children: true });
  }
  const sprite = PIXI.Sprite.from(PIXI.Texture.from(isSoundOn ? soundIconOn : soundIconOff));
  soundIconContainer.addChild(sprite);
}
const initSoundButton = () => {
  updateSoundIcon();

  soundIconContainer.x = (app.screen.width - soundIconContainer.width) * .5;
  soundIconContainer.y = ratio > 1 ? SOUND_CONTAINER_DESKTOP_Y : SOUND_CONTAINER_MOBILE_Y;

  soundIconContainer.interactive = true;
  soundIconContainer.buttonMode = true;
  soundIconContainer.on('pointerdown', () => {
    isSoundOn = !isSoundOn;
    updateSoundIcon();
    if (isSoundOn) {
      soundInstance.volume = .3;
    } else {
      soundInstance.volume = 0;
    }
  });

  return soundIconContainer;
}




// SNOW
const snowFlakes = [];
const numSnowFlakes = 48;
const baseSpeedY = .002 * app.screen.height;
const baseSpeedX = .001 * app.screen.height;
const snowContainer = new PIXI.Container();

const initSnow = () => {
  // app.stage.addChild(snowContainer);
  for(let i = 1; i <= numSnowFlakes; i++) {
    const image = snowflake1;
    let texture = PIXI.Texture.from(image);
    const flake = PIXI.Sprite.from(texture);
    flake.speedY = randomRange(2 * baseSpeedY, 3.6 * baseSpeedY);
    flake.angle = getRandomInt(0, 359);
    flake.speedX = -randomRange(5 * baseSpeedX, 8 * baseSpeedX);
    flake.x = Math.random() * (app.screen.width + 0.5 * app.screen.width);
    flake.y = Math.random() * app.screen.height;
    snowFlakes.push(flake);
    snowContainer.addChild(flake);
  }
};

const moveSnowflakes = (delta) => {
  for(let i = 0; i < snowFlakes.length; i++) {
    let snowFlake = snowFlakes[i];
    snowFlake.x += delta * snowFlake.speedX;
    snowFlake.y += delta * snowFlake.speedY;
    if(snowFlake.y > app.screen.height) {
      snowFlake.speedY = randomRange(2.5 * baseSpeedY, 4.6 * baseSpeedY);
      snowFlake.speedX = -randomRange(5 * baseSpeedX, 8 * baseSpeedX);
      snowFlake.y = -snowFlake.height;
      snowFlake.x = Math.random() * (app.screen.width + 0.5 * app.screen.width);
    }
  }
};

const restartGame = () => {
  gameOverContainer.destroy({ children: true });
  while (itemsContainer.children.length > 0) {
    itemsContainer.children[0].destroy({ children: true });
  }
  while (pointAnimationsContainer.children.length > 0) {
    gsap.killTweensOf(pointAnimationsContainer.children[0]);
    pointAnimationsContainer.children[0].destroy({ children: true });
  }
  while (badDeleteContainer.children.length > 0) {
    badDeleteContainer.children[0].destroy({ children: true });
  }
  score = 0;
  acceleration = 1;
  decelerationSpeed = MAX_ITEM_SPEED;
  newItemCounter = 1;
  currentBagSpeed = 0;
  bagState = BAG_STATE_NORMAL;
  bagDirection = 0;
  isLoadingApiData = false;
  apiError = undefined;
  gsap.killTweensOf(bagContainer);
  bagContainer.alpha = 1;
  bagContainer.x = app.screen.width * .5;
  startTime();
  addInitialItems();
  showHideElements(1);
  gameState = GAME_STATE_RUNNING;
};

const getApiLoadingTextField = (color = 0x2C3339) => {
  const container = new PIXI.Container();

  const loadingTextField = new PIXI.Text('0',{fontFamily : 'Inter', fontSize: 20, fill : color, align : 'left'});
  loadingTextField.text = "Loading leaderboard...";
  container.addChild(loadingTextField);

  return container;
};

const getApiErrorTextField = (color = 0x2C3339) => {
  const container = new PIXI.Container();

  const loadingTextField = new PIXI.Text('0',{fontFamily : 'Inter', fontSize: 20, fill : color, align : 'center', wordWrap: true, wordWrapWidth: 280, lineHeight: 30});
  loadingTextField.text = apiError ? apiError : API_ERROR_MESSAGE;
  container.addChild(loadingTextField);

  return container;
};

const getPlayerTextField = (name, score, currentGameScore, color = 0x000000) => {
  const container = new PIXI.Container();

  const playerNameTextField = new PIXI.Text('0',{fontFamily : 'Inter', fontSize: ratio > 1 ? 20 : 18, fill : color, align : 'left'});
  playerNameTextField.text = name;
  container.addChild(playerNameTextField);

  const scoreTextField = new PIXI.Text('0',{fontFamily : 'Inter', fontSize: ratio > 1 ? 20 : 18, fill : color, align : 'left'});
  scoreTextField.text = currentGameScore !== undefined ? `${currentGameScore} (${String(score)})` : String(score);
  scoreTextField.x = 288 - scoreTextField.width;
  container.addChild(scoreTextField);

  return container;
};

const getPlayAgainButton = () => {
  let texture = PIXI.Texture.from(again);
  return PIXI.Sprite.from(texture);
};

const getGameOverTitle = () => {
  const textField = new PIXI.Text('0',{fontFamily : 'Inter', fontSize: ratio > 1 ? 26 : 24, fontWeight: 900, fill : 0x000000, align : 'center'});
  textField.text = 'Leaderboard';
  return textField;
};

const getStandingsTable = () => {
  const tableContainer = new PIXI.Container();
  if (standingData.length > 0 && playerData.name) {
    const heightPerItem = ratio > 1 ? 40 : 36;

    standingData.forEach((item, index) => {
      const itemContainer = new PIXI.Container();
      const p = getPlayerTextField(`${item.position}. ${item.name}`, item.score, undefined, item.name === playerData.name ? 0xfa2b0b : 0x000000);
      const lineSprite = PIXI.Sprite.from(line);
      itemContainer.addChild(p);
      itemContainer.addChild(lineSprite);
      lineSprite.y = heightPerItem - lineSprite.height;
      p.y = (heightPerItem - p.height) * .5;
      p.x = (lineSprite.width - p.width) * .5;
      if (ratio < 1) {
        itemContainer.y = index * heightPerItem;
      } else {
        if (index < 5) {
          itemContainer.y = index * heightPerItem;
        } else {
          itemContainer.x = app.screen.width * .4;
          itemContainer.y = (index - 5) * heightPerItem;
        }
      }
      tableContainer.addChild(itemContainer);
    });
  }
  return tableContainer;
};

const accelerate = () => {
  acceleration += ACCELERATION_CONSTANT;
};

const resizeHandler = () => {
  const { innerWidth, innerHeight } = window;

  document.body.style.height = `${innerHeight}px`;
  document.body.style.width = `${innerWidth}px`;
  document.documentElement.style.height = `${innerHeight}px`;
  document.documentElement.style.width = `${innerWidth}px`;
  mainContainer.style.height = `${innerHeight}px`;
  mainContainer.style.width = `${innerWidth}px`;
  tutorial1.style.height = `${innerHeight}px`;
  tutorial1.style.width = `${innerWidth}px`;
  tutorial2.style.height = `${innerHeight}px`;
  tutorial2.style.width = `${innerWidth}px`;

  let newWidth = innerWidth;
  let newHeight = innerWidth / ratio;

  if (newWidth > logicalWidth) {
    newWidth = logicalWidth;
    newHeight = logicalWidth / ratio;
  }

  if (newHeight > logicalHeight) {
    newHeight = logicalHeight;
    newWidth = newHeight * ratio;
  }

  if (newHeight > innerHeight) {
    newHeight = innerHeight;
    newWidth = newHeight * ratio;
  }



  app.view.style.width = `${newWidth}px`;
  app.view.style.height = `${newHeight}px`;

  app.resize(newWidth, newHeight);
};

const BAG_STATE_NORMAL = "BAG_STATE_NORMAL";
const BAG_STATE_ACCELERATE_LEFT = "BAG_STATE_ACCELERATE_LEFT";
const BAG_STATE_ACCELERATE_RIGHT = "BAG_STATE_ACCELERATE_RIGHT";
const BAG_STATE_DECELERATE = "BAG_STATE_DECELERATE"
const INITIAL_BAG_SPEED = 2 * speedCorrection;
const MAX_BAG_SPEED = 9 * speedCorrection;
const BAG_ACCELERATION = 1.5 * bagAccelerationCorrection;
const BAG_DECELERATION = 0.65;
const MIN_ITEM_SPEED = 2 * speedCorrection;
const MAX_ITEM_SPEED = 5 * speedCorrection;
const INITIAL_DURATION = 60000;
const CLOCK_TIME = 10000;
const INIT_ITEMS_NUM = ratio > 1 ? 6 : 4;
const MAX_ITEMS_NUM = ratio > 1 ? 30 : 22;
const ITEM_COUNTER_MAX_VALUE = 6;
const API_ERROR_MESSAGE = "An error occurred while trying to load the leaderboard!"

let newItemCounter = 1;
let currentBagSpeed = 0;
let bagState = BAG_STATE_NORMAL;
let bagDirection = 0;
let time;
let startedAt;
let isLoadingApiData = false;
let apiError = undefined;

const getApiData = () => {
  standingData = [];
  playerData = {};
  isLoadingApiData = true;
  apiError = undefined;

  axios.post('https://chgame.droxic.com/api/players', {
    name: playerName,
    score: score
  })
    .then(function (response) {
      axios.get('https://chgame.droxic.com/api/players?top=10')
        .then(function (response) {
          standingData = [ ...response.data ];
          axios.get(`https://chgame.droxic.com/api/players?name=${playerName}`)
            .then(function (response) {
              playerData = { ...response.data };
              isLoadingApiData = false;
            })
            .catch(function (error) {
              console.log(error);
              apiError = API_ERROR_MESSAGE;
              isLoadingApiData = false;
            })
        })
        .catch(function (error) {
          console.log(error);
          apiError = API_ERROR_MESSAGE;
          isLoadingApiData = false;
        })

    })
    .catch(function (error) {
      console.log(error);
      apiError = API_ERROR_MESSAGE;
      isLoadingApiData = false;
    });
};

const showHideElements = (direction = 1) => {
  const baseAnimObject = {
    duration: .85,
    alpha: direction,
    ease: direction === 1 ? Power4.easeOut : Power4.easeInOut
  }

  const scoreTimePadding = ratio > 1 ? DESKTOP_SCORE_TIME_PADDING : MOBILE_SCORE_TIME_PADDING;
  gsap.to([scoreTextContainer, timeTextContainer], {
    ...baseAnimObject,
    y: direction === 1 ? scoreTimePadding : -scoreTextContainer.height,
  });

  const soundButtonY = ratio > 1 ? SOUND_CONTAINER_DESKTOP_Y : SOUND_CONTAINER_MOBILE_Y;
  gsap.to(soundIconContainer, {
    ...baseAnimObject,
    y: direction === 1 ? soundButtonY : -soundIconContainer.height,
  });

  const signStarX = ratio > 1 ? SIGN_DESKTOP_X : SIGN_MOBILE_X;
  gsap.to(signStarSprite, {
    ...baseAnimObject,
    x: direction === 1 ? signStarX : -(signStarSprite.width * .5),
  });

  const signClockX = ratio > 1 ? SIGN_DESKTOP_X : SIGN_MOBILE_X;
  gsap.to(signClockSprite, {
    ...baseAnimObject,
    x: direction === 1 ? app.screen.width - signClockX : app.screen.width + signClockSprite.width * .5,
  });

  if (ratio < 1) {
    gsap.to(leftArrowSprite, {
      ...baseAnimObject,
      x: direction === 1 ? ARROW_BUTTON_SPACE : -leftArrowSprite.width,
    });

    gsap.to(rightArrowSprite, {
      ...baseAnimObject,
      x: direction === 1 ?
        app.screen.width - rightArrowSprite.width - ARROW_BUTTON_SPACE :
        app.screen.width + rightArrowSprite.width,
    });
  }

  if (bagContainer.alpha > 0) {
    gsap.to(bagContainer, { ...baseAnimObject, delay: .5 });
  }
}

const showGameOver = () => {
  gameState = GAME_STATE_GAME_OVER;

  showHideElements(0);

  gameOverContainer = new PIXI.Container();
  app.stage.addChild(gameOverContainer);

  if (isLoadingApiData) {
    const loadingApiTextField = getApiLoadingTextField();
    gameOverContainer.addChild(loadingApiTextField);

    loadingApiTextField.x = (app.screen.width - loadingApiTextField.width) * .5;
    loadingApiTextField.y = (app.screen.height - loadingApiTextField.height) * .5;
  }

  if (!isLoadingApiData && apiError) {
    const apiErrorTextField = getApiErrorTextField();
    gameOverContainer.addChild(apiErrorTextField);

    const playAgain = getPlayAgainButton();
    gameOverContainer.addChild(playAgain);
    playAgain.interactive = true;
    playAgain.buttonMode = true;
    playAgain.on('pointerdown', restartGame);


    const verticalSpace = app.screen.height - apiErrorTextField.height - playAgain.height;
    const space = verticalSpace / 3;

    apiErrorTextField.x = (app.screen.width - apiErrorTextField.width) * .5;
    apiErrorTextField.y = space;

    playAgain.x = (app.screen.width - playAgain.width) * .5;
    playAgain.y = apiErrorTextField.y + apiErrorTextField.height + space;
  }

  if (!isLoadingApiData && !apiError && playerData && standingData) {
    const title = getGameOverTitle();
    gameOverContainer.addChild(title);

    const table = getStandingsTable();
    gameOverContainer.addChild(table);

    const myPosition = getPlayerTextField(`${playerData.position}. ${playerData.name}`, playerData.score, score, 0xfa2b0b);
    gameOverContainer.addChild(myPosition);

    const playAgain = getPlayAgainButton();
    gameOverContainer.addChild(playAgain);

    const desktopSpaceComp = ratio > 0 ? 50 : 0;

    const verticalSpace = app.screen.height - desktopSpaceComp - title.height - table.height - myPosition.height - playAgain.height;
    const space = verticalSpace / 5;

    title.x = (app.screen.width - title.width) * .5;
    title.y = (desktopSpaceComp / 2) + space;

    table.x = (app.screen.width - table.width) * .5;
    table.y = title.y + title.height + space;

    myPosition.x = (app.screen.width - myPosition.width) * .5;
    myPosition.y = table.y + table.height + space;

    playAgain.x = (app.screen.width - playAgain.width) * .5;
    playAgain.y = myPosition.y + myPosition.height + space;
    playAgain.interactive = true;
    playAgain.buttonMode = true;
    playAgain.on('pointerdown', restartGame);

    gsap.fromTo(title, { alpha: 0 }, { alpha: 1, duration: .5, delay: .5 });
    const { length } = table.children;

    for (let i = 0; i < length + 2; i++) {
      const target = i < length ? table.children[i] : (i === length + 1 ? myPosition : playAgain);
      gsap.fromTo(target, { alpha: 0 }, { alpha: 1, duration: .5, delay: .5 + (i * .025) });
    }
  }
};

const startTime = () => {
  time = INITIAL_DURATION;
  startedAt = (new Date()).getTime();
}

const initBagContainer = () => {
  app.stage.addChild(bagContainer);
}

const addBackground = () => {
  let texture = PIXI.Texture.from(ratio > 1 ? backgroundDesktop : backgroundMobile);
  const sprite = PIXI.Sprite.from(texture);
  sprite.width = app.screen.width;
  sprite.scale.y = sprite.scale.x;
  if (sprite.height < app.screen.height) {
    sprite.height = app.screen.height;
    sprite.scale.x = sprite.scale.y;
  }
  sprite.x = (app.screen.width - sprite.width) / 2;
  sprite.y = (app.screen.height - sprite.height) / 2;
  app.stage.addChild(sprite);
}

const SIGN_MOBILE_X = -35;
const SIGN_DESKTOP_X = 10;
const showSign = (signSprite) => {
  const toX = signSprite === signStarSprite ? (ratio > 1 ? 100 : 65) : app.screen.width - (ratio > 1 ? 100 : 65);
  const backX = signSprite === signStarSprite ? (ratio > 1 ? SIGN_DESKTOP_X : SIGN_MOBILE_X) : app.screen.width - (ratio > 1 ? SIGN_DESKTOP_X : SIGN_MOBILE_X);
    gsap.killTweensOf(signSprite);
  gsap.to(signSprite, {
    duration: .5,
    x: toX,
    ease: Power4.easeOut,
    onComplete: () => {
      gsap.to(signSprite, {
        duration: .5,
        delay: .5,
        x: backX,
        ease: Power4.easeOut,
      })
    }
  });
};

let leftArrowSprite;
let rightArrowSprite;
const ARROW_BUTTON_SPACE = 16;

const addBorders = () => {
  app.stage.addChild(bordersContainer);
  bordersContainer.addChild(initScoreTextField());
  bordersContainer.addChild(initTimeTextField());
  bordersContainer.addChild(initSoundButton())

  const foregroundTexture = PIXI.Texture.from(ratio > 1 ? foregroundDesktop : foregroundMobile);
  const foregroundSprite = PIXI.Sprite.from(foregroundTexture);
  foregroundSprite.width = app.screen.width;
  foregroundSprite.scale.y = foregroundSprite.scale.x;
  foregroundSprite.y = app.screen.height - foregroundSprite.height;
  bordersContainer.addChild(foregroundSprite);

  const signStarTexture = PIXI.Texture.from(sign_star);
  signStarSprite = PIXI.Sprite.from(signStarTexture);
  signStarSprite.anchor.set(0.5, 1);
  signStarSprite.x = ratio > 1 ? SIGN_DESKTOP_X : SIGN_MOBILE_X;
  signStarSprite.y = ratio > 1 ? 220 : 150;
  bordersContainer.addChild(signStarSprite);

  const signClockTexture = PIXI.Texture.from(sign_clock);
  signClockSprite = PIXI.Sprite.from(signClockTexture);
  signClockSprite.anchor.set(0.5, 1);
  signClockSprite.x = app.screen.width - (ratio > 1 ? SIGN_DESKTOP_X : SIGN_MOBILE_X);
  signClockSprite.y = ratio > 1 ? 220 : 150;
  bordersContainer.addChild(signClockSprite);

  if (ratio < 1) {
    const leftTexture = PIXI.Texture.from(left);
    leftArrowSprite = PIXI.Sprite.from(leftTexture);
    leftArrowSprite.x = ARROW_BUTTON_SPACE;
    leftArrowSprite.y = app.screen.height - leftArrowSprite.height;
    bordersContainer.addChild(leftArrowSprite);

    const rightTexture = PIXI.Texture.from(right);
    rightArrowSprite = PIXI.Sprite.from(rightTexture);
    rightArrowSprite.x = app.screen.width - rightArrowSprite.width - ARROW_BUTTON_SPACE;
    rightArrowSprite.y = app.screen.height - rightArrowSprite.height;
    bordersContainer.addChild(rightArrowSprite);

    return;
  }

  let borderTexture = PIXI.Texture.from(border);
  const longSide = app.screen.width;
  for (let i = 0; i < 4; i++) {
    const sprite = PIXI.Sprite.from(borderTexture);
    sprite.width = longSide;
    sprite.scale.y = sprite.scale.x;
    if ( i === 1) {
      sprite.angle = 90;
      sprite.x = app.screen.width;
    }
    if (i === 2) {
      sprite.angle = 180;
      sprite.x = app.screen.width;
      sprite.y = app.screen.height;
    }
    if (i === 3) {
      sprite.angle = 270;
      sprite.y = app.screen.height;
    }
    bordersContainer.addChild(sprite);
  }


  const cornerTextures = [corner1, corner2, corner3, corner4];
  for (let i = 0; i < 4; i++) {
    let cornerTexture = PIXI.Texture.from(cornerTextures[i]);
    const sprite = PIXI.Sprite.from(cornerTexture);
    if (i === 1) {
      sprite.x = app.screen.width - sprite.width;
    }

    if (i === 2) {
      sprite.x = app.screen.width - sprite.width;
      sprite.y = app.screen.height - sprite.height;
    }

    if (i === 3) {
      sprite.y = app.screen.height - sprite.height;
    }
    bordersContainer.addChild(sprite);
  }
};

const initBag = () => {
  const images = [bag];
  const bagTextureArray = [];
  for (let i = 0; i < images.length; i++) {
    let texture = PIXI.Texture.from(images[i]);
    bagTextureArray.push(texture);
  }
  bagAnimatedSprite = new PIXI.AnimatedSprite(bagTextureArray);
  bagAnimatedSprite.animationSpeed = 0.13 / acceleration;
  bagAnimatedSprite.x = -(bagAnimatedSprite.width * .5);
  bagAnimatedSprite.y = -bagAnimatedSprite.height;
  bagAnimatedSprite.stop();
  bagContainer.addChild(bagAnimatedSprite);

  const goodAnimationImages = [goodFrame1, goodFrame4];
  const goodAnimationTextureArray = [];
  for (let i = 0; i < goodAnimationImages.length; i++) {
    let texture = PIXI.Texture.from(goodAnimationImages[i]);
    goodAnimationTextureArray.push(texture);
  }
  bagGoodAnimationSprite = new PIXI.AnimatedSprite(goodAnimationTextureArray);
  bagGoodAnimationSprite.x = -(bagGoodAnimationSprite.width * .5);
  bagGoodAnimationSprite.y = -(bagAnimatedSprite.height + bagGoodAnimationSprite.height) + 35;
  bagGoodAnimationSprite.animationSpeed = 0.13 / acceleration;
  bagGoodAnimationSprite.stop();
  bagGoodAnimationSprite.loop = false;
  bagGoodAnimationSprite.onComplete = function () {
    bagGoodAnimationSprite.stop();
    bagGoodAnimationSprite.visible = false;
  };
  bagGoodAnimationSprite.visible = false;
  bagContainer.addChild(bagGoodAnimationSprite);

  const badAnimationImages = [badFrame1, badFrame1, badFrame2, badFrame3, badFrame1, badFrame2, badFrame3];
  const badAnimationTextureArray = [];
  for (let i = 0; i < badAnimationImages.length; i++) {
    let texture = PIXI.Texture.from(badAnimationImages[i]);
    badAnimationTextureArray.push(texture);
  }
  bagBadAnimationSprite = new PIXI.AnimatedSprite(badAnimationTextureArray);
  bagBadAnimationSprite.x = -(bagBadAnimationSprite.width * .5);
  bagBadAnimationSprite.y = -(bagAnimatedSprite.height + bagBadAnimationSprite.height) + 35;
  bagBadAnimationSprite.animationSpeed = 0.13 / acceleration;
  bagBadAnimationSprite.stop();
  bagBadAnimationSprite.loop = false;
  bagBadAnimationSprite.onComplete = function () {
    bagBadAnimationSprite.stop();
    bagBadAnimationSprite.visible = false;
    gsap.killTweensOf(bagContainer);

    gsap.to(bagContainer, {
      duration: .75,
      alpha: 0,
      ease: Power4.easeOut
    });
  };
  bagBadAnimationSprite.visible = false;
  bagContainer.addChild(bagBadAnimationSprite);

  bagContainer.width *= scaleCorrection;
  bagContainer.scale.y = bagContainer.scale.x;
  bagContainer.x = app.screen.width * .5;
  bagContainer.y = ratio > 1 ?
    (app.screen.height - (bagAnimatedSprite.height * 0.2)) :
    (app.screen.height - bagAnimatedSprite.height);
}

const initItemsContainer = () => {
  app.stage.addChild(itemsContainer);
  app.stage.addChild(pointAnimationsContainer);
  app.stage.addChild(badDeleteContainer);
  app.stage.addChild(snowContainer);
}



const getClockChance = t => {
  const MAX_CLOCK_CHANCE = ratio > 1 ? 6 : 9; // 9;
  const MIN_CLOCK_CHANCE = ratio > 1 ? 3 : 5; // 3;
  let clockChance = MAX_CLOCK_CHANCE;

  if (startedAt !== undefined) {
    const time = Math.round((t - startedAt) / 1000);
    clockChance = Math.round(scale(time, 0, 60, MAX_CLOCK_CHANCE, MIN_CLOCK_CHANCE));
  }

  return Math.max(clockChance, MIN_CLOCK_CHANCE);
};

const getStarChance = () => {
  return 7; // 10;
};

const getBombChance = () => {
  return 18; // 20;
}

const addItem = (x, y) => {
  const clockChance = getClockChance((new Date()).getTime());
  const clockRange = [1, clockChance];

  const starChance = getStarChance();
  const starRange = [clockChance + 1, clockChance + starChance];

  const bombChance = getBombChance();
  const bombRange = [starRange[1] + 1, starRange[1] + bombChance];

  const randNum = getRandomInt(1, 100);
  let images;
  let isBad = false;
  let isClock = false;
  let isStar = false;
  let ballIndex;

  if (isNumberInRange(randNum, clockRange)) {
    isClock = true;
    images = [clock1, clock2, clock3, clock4];
  }

  if (isNumberInRange(randNum, starRange)) {
    isStar = true;
    images = [star1, star2, star3, star4, star5];
  }

  if (isNumberInRange(randNum, bombRange)) {
    isBad = true;
    const bombImages = [bomb1, bomb2, bomb3, bomb4, bomb5, bomb6, bomb7, bomb8, bomb9, bomb10, bomb11, bomb12, bomb13, bomb14, bomb15, bomb16];
    const bombIndex = getRandomInt(0, bombImages.length - 1);
    images = [bombImages[bombIndex]];
  }

  if (!isBad && !isClock && !isStar) {
    const ballImages = [ball1, ball2, ball3, ball4, ball5, ball6, ball7, ball8, ball9, ball10, ball11, ball12, ball13, ball4];
    ballIndex = getRandomInt(0, ballImages.length - 1);
    images = [ballImages[ballIndex]]
  }

  const itemTextureArray = [];
  for (let i = 0; i < images.length; i++) {
    let texture = PIXI.Texture.from(images[i]);
    itemTextureArray.push(texture);
  }
  const itemAnimatedSprite = new PIXI.AnimatedSprite(itemTextureArray);

  itemAnimatedSprite.animationSpeed = 0.13 / acceleration;
  itemAnimatedSprite.isBad = isBad;
  itemAnimatedSprite.isClock = isClock;
  itemAnimatedSprite.isStar = isStar;
  itemAnimatedSprite.speed = getRandomFloat(MIN_ITEM_SPEED, MAX_ITEM_SPEED);
  if (!isBad && !isClock && !isStar) {
    itemAnimatedSprite.score = ballIndex + 1;
  }
  if (isClock || isStar) {
    itemAnimatedSprite.score = 20;
  }
  itemAnimatedSprite.x = x;
  itemAnimatedSprite.y = y;


  itemAnimatedSprite.angle = getRandomFloat(0, 259);
  itemAnimatedSprite.angleSpeed = getRandomInt(1, 2) * (getRandomInt(0, 1) === 0 ? 1 : -1);
  itemAnimatedSprite.anchor.set(.5, .5);

  itemAnimatedSprite.width *= scaleCorrection;
  itemAnimatedSprite.scale.y = itemAnimatedSprite.scale.x;

    itemAnimatedSprite.play();
  itemsContainer.addChild(itemAnimatedSprite);
}

const pointAnimationImages = [point1, point2, point3, point4, point5, point6, point7, point8, point9, point10, point11, point12, point13, point14, point15, point16, point17, point18, point19, point20, timeOver];
const showPointAnimation = (points) => {
  if (pointAnimationImages[points - 1]) {
    let texture = PIXI.Texture.from(pointAnimationImages[points - 1]);
    let sprite = new PIXI.Sprite(texture);
    sprite.x = (app.screen.width - sprite.width) * .5;
    sprite.y = (app.screen.height - sprite.height) * .5;
    gsap.to(sprite, {
      y: "-=100",
      duration: points === 21 ? 2.2 : 1.25, // 21 is time over
      ease: Power4.easeOut,
      onComplete: () => {
        gsap.killTweensOf(sprite);
        sprite.destroy();
      }
    });
    gsap.to(sprite, {
      alpha: 0,
      duration: .75,
      delay: points === 21 ? 1.2 : .25,
      ease: Power4.easeIn
    })
    pointAnimationsContainer.addChild(sprite);
  }
}

const incrementNewItemCounter = () => {
  newItemCounter += 1;
  if (newItemCounter > ITEM_COUNTER_MAX_VALUE) newItemCounter = 1;
}

const getRandomNexItemX = () => {
  return getRandomFloat(0, app.screen.width);
}

const getRandomNexItemY = () => {
  return getRandomFloat(-(app.screen.height * 2), 0);
}

const addInitialItems = () => {
  for (let i = 1; i <= INIT_ITEMS_NUM; i++) {
    addItem(getRandomNexItemX(), getRandomNexItemY());
  }
}

const addNewItem = () => {
  if (itemsContainer.children.length < MAX_ITEMS_NUM) {
    addItem(getRandomNexItemX(), getRandomNexItemY());
    incrementNewItemCounter();
    if (newItemCounter === 1 && itemsContainer.children.length < MAX_ITEMS_NUM) {
      addItem(getRandomNexItemX(), getRandomNexItemY());
    }
  }
}

const moveItems = (delta) => {
  const itemsToRemove = [];
  itemsContainer.children.forEach(item => {
    if (gameState !== GAME_STATE_GAME_OVER && gameState !== GAME_STATE_CRASHED && !item.toBeDestroyed) {
      item.y += delta * item.speed * acceleration;
      item.angle += delta * item.angleSpeed;
    }

    if (gameState === GAME_STATE_CRASHED && !item.toBeDestroyed) {
      item.y += delta * item.speed * scale(decelerationSpeed, 0, MAX_ITEM_SPEED, 0, 1);
      item.angle += delta * item.angleSpeed * scale(decelerationSpeed, 0, MAX_ITEM_SPEED, 0, 1);
    }

    if (item.y > app.screen.height + (item.height * .5)) {
      itemsToRemove.push(item);
    }
  });

  const itemsToRemoveLength = itemsToRemove.length;

  if (itemsToRemoveLength > 0) {
    itemsToRemove.forEach(item => {
      item.destroy();
    });

    for (let i = 0; i < itemsToRemoveLength; i++) {
      addNewItem();
    }
  }
}

const checkItemsCollision = () => {
  const bagRect = bagAnimatedSprite.getBounds();
  bagRect.x = bagRect.x + 18;
  bagRect.width = 80;
  bagRect.y = bagRect.y + 18;
  bagRect.height = 25;
  let destroyAllVisibleBombs = false;
  itemsContainer.children.forEach(item => {
    if (item.y > app.screen.height * .5) {
      const itemRect = item.getBounds();
      itemRect.x = itemRect.x + .1 * itemRect.width;
      itemRect.y = itemRect.y + .1 * itemRect.height;
      itemRect.width *= .8;
      itemRect.height *= .8;
      if (bagRect.intersects(itemRect)) {
        if (item.isBad && !item.toBeDestroyed) {
          if (isSoundOn) {
            crashSoundInstance = sound.play(dieSound);
            crashSoundInstance.volume = .6;
          }
          bagBadAnimationSprite.visible = true;
          bagBadAnimationSprite.gotoAndPlay(1);
          item.destroy();
          stopGame();
        }

        if (item.isClock) {
          bagGoodAnimationSprite.visible = true;
          bagGoodAnimationSprite.gotoAndPlay(1);
          score += item.score;
          showPointAnimation(item.score);
          item.destroy();
          time += CLOCK_TIME;
          showSign(signClockSprite);
          if (isSoundOn) {
            clockSoundInstance = sound.play(clockSound);
            clockSoundInstance.volume = .7;
          }
        }

        if (item.isStar) {
          bagGoodAnimationSprite.visible = true;
          bagGoodAnimationSprite.gotoAndPlay(1);
          score += item.score;
          showPointAnimation(item.score);
          item.destroy();
          destroyAllVisibleBombs = true;
          showSign(signStarSprite);
          if (isSoundOn) {
            starSoundInstance = sound.play(starSound);
            starSoundInstance.volume = .5;
          }
        }

        if (!item.isBad && !item.isClock && !item.isStar) {
          bagGoodAnimationSprite.visible = true;
          bagGoodAnimationSprite.gotoAndPlay(1);
          score += item.score;
          showPointAnimation(item.score);
          if (isSoundOn) {
            collectableSoundInstance = sound.play(collectSounds[getRandomInt(0, collectSounds.length - 1)]);
            collectableSoundInstance.volume = .5;
          }
          item.destroy();
        }

        addNewItem();
      }
    }
  });

  if (destroyAllVisibleBombs) {
    itemsContainer.children.forEach(item => {
      if (item.isBad && !item.toBeDestroyed && item.y >= 0) {
        item.toBeDestroyed = true;
        const images = [evilDelete1, evilDelete2, evilDelete3, evilDelete4, evilDelete5, evilDelete6];
        const textureArray = [];
        for (let i = 0; i < images.length; i++) {
          let texture = PIXI.Texture.from(images[i]);
          textureArray.push(texture);
        }
        const deleteSprite = new PIXI.AnimatedSprite(textureArray);
        deleteSprite.animationSpeed = 0.13 / acceleration;
        deleteSprite.anchor.set(.5, .5);
        deleteSprite.x = item.x;
        deleteSprite.y = item.y;
        deleteSprite.loop = false;
        deleteSprite.play();
        deleteSprite.onComplete = () => {
          item.destroy();
          deleteSprite.destroy();
        }
        badDeleteContainer.addChild(deleteSprite);
      }
    });
    // addNewItem();
  }
}

let decelerationSpeed = MAX_ITEM_SPEED;

const stopGame = () => {
  decelerationSpeed = MAX_ITEM_SPEED * acceleration;
  gameState = GAME_STATE_CRASHED;
  getApiData();
}

const decelerateGame = () => {
  decelerationSpeed /= CRASH_DECELERATION_FACTOR;

  itemsContainer.children.forEach(item => {
    item.alpha = item.alpha / CRASH_DECELERATION_FACTOR;
  });

  if (
    (decelerationSpeed < 0.05 && playerData.name && standingData.length > 0) ||
    apiError
  ) {
    itemsContainer.children.forEach(item => {
      item.alpha = 0;
    });

    showGameOver();
  }
};

const accelerateBag = (delta) => {
  currentBagSpeed = Math.min(currentBagSpeed * BAG_ACCELERATION, MAX_BAG_SPEED);
  const newX = bagContainer.x + (currentBagSpeed * bagDirection);
  bagContainer.x = Math.min(Math.max(0, newX), app.screen.width);
}

const decelerateBag = (delta) => {
  currentBagSpeed = currentBagSpeed * BAG_DECELERATION;
  const newX = bagContainer.x + (currentBagSpeed * bagDirection);
  bagContainer.x = Math.min(Math.max(0, newX), app.screen.width);
  if (currentBagSpeed <= 0.01) {
    bagState = BAG_STATE_NORMAL;
    currentBagSpeed = 0;
    bagDirection = 0;
  }
}

function setup() {
  removePreloader();
  addBackground();
  initItemsContainer();
  initBagContainer();
  initBag();
  initSnow();
  addBorders();
  addInitialItems();
  resizeHandler();
  startTime();

  // Keyboard and touch/click events
  app.view.addEventListener('touchstart', (event) => {
    const isLeftSide = event.changedTouches[0].pageX <= app.screen.width * .5;

    if (isLeftSide && bagState !== BAG_STATE_ACCELERATE_LEFT) {
      bagDirection = -1;
      currentBagSpeed = INITIAL_BAG_SPEED;
      bagState = BAG_STATE_ACCELERATE_LEFT;
    }

    if (!isLeftSide && bagState !== BAG_STATE_ACCELERATE_RIGHT) {
      bagDirection = 1;
      currentBagSpeed = INITIAL_BAG_SPEED;
      bagState = BAG_STATE_ACCELERATE_RIGHT;
    }
  });

  app.view.addEventListener('touchend', (event) => {
    const isLeftSide = event.changedTouches[0].pageX <= app.screen.width * .5;

    if (isLeftSide && bagState === BAG_STATE_ACCELERATE_LEFT) {
      bagState = BAG_STATE_DECELERATE;
    }

    if (!isLeftSide && bagState === BAG_STATE_ACCELERATE_RIGHT) {
      bagState = BAG_STATE_DECELERATE;
    }
  });

  document.addEventListener('keydown', (event) => {
    if (event.code === "ArrowLeft" && bagState !== BAG_STATE_ACCELERATE_LEFT && !event.repeat) {
      bagDirection = -1;
      currentBagSpeed = INITIAL_BAG_SPEED;
      bagState = BAG_STATE_ACCELERATE_LEFT;
    }

    if (event.code === "ArrowRight" && bagState !== BAG_STATE_ACCELERATE_RIGHT && !event.repeat) {
      bagDirection = 1;
      currentBagSpeed = INITIAL_BAG_SPEED;
      bagState = BAG_STATE_ACCELERATE_RIGHT;
    }
  });

  document.addEventListener('keyup', (event) => {
    if (event.code === "ArrowLeft" && bagState === BAG_STATE_ACCELERATE_LEFT) {
      bagState = BAG_STATE_DECELERATE;
    }

    if (event.code === "ArrowRight" && bagState === BAG_STATE_ACCELERATE_RIGHT) {
      bagState = BAG_STATE_DECELERATE;
    }
  });

  // SOUND
  soundInstance = sound.play(music);
  soundInstance.volume = 0.18;
  soundInstance.loop = true;

  app.ticker.add((delta) => {
    moveSnowflakes(delta);
    if (gameState === GAME_STATE_CRASHED) {
      decelerateGame();
    }

    if (gameState !== GAME_STATE_GAME_OVER) {
      moveItems(delta);
      if (bagState === BAG_STATE_ACCELERATE_LEFT || bagState === BAG_STATE_ACCELERATE_RIGHT) {
        accelerateBag(delta);
      }
      if (bagState === BAG_STATE_DECELERATE) {
        decelerateBag(delta);
      }
      if (gameState === GAME_STATE_RUNNING) {
        checkItemsCollision();

        const now = (new Date()).getTime();
        const timeDiff = now - startedAt;
        const remainingTime = time - timeDiff;

        if (remainingTime <= 0) {
          timeText.text = "0";
          showPointAnimation(21);
          if (isSoundOn) {
            crashSoundInstance = sound.play(dieSound);
            crashSoundInstance.volume = .6;
          }
          stopGame();
        }

        if (remainingTime > 0) {
          timeText.text = String(Math.ceil(remainingTime / 1000));
        }
      }
      scoreText.text = score;
    }

    if (gameState !== GAME_STATE_CRASHED && gameState !== GAME_STATE_GAME_OVER) {
      accelerate();
    }
  });
}

window.addEventListener('resize', resizeHandler, false);
window.visualViewport.addEventListener('resize', resizeHandler, false);
resizeHandler();
